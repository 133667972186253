import { ENUM_PRODUCT_FIELD_DATA_TYPE, ENUM_DATE_TYPE_DEFAULT_VALUE } from '../../../../../misc/commons'
import moment, { unix } from 'moment-timezone'
import store from '../../../../../store'
export default {
    props: {
        item: Object,
        itemKey: Number | String,
        producFieldMetaList: Array | null,
        recallDisabled: { type: Boolean, default: false },
        isCustomizedContent: { type: Boolean, default: false },
        isShow: { type: Boolean, default: true }
    },
    data() {
        return {
            dataTypeOptions: [],
            fieldList: [],
            dragData: {},
            product: null,
            defaultValue: null,
            enumProductFieldDataType: ENUM_PRODUCT_FIELD_DATA_TYPE,
            enumDateTypeDefaultValue: ENUM_DATE_TYPE_DEFAULT_VALUE,
            orgType: store.state.userType,
        }
    },
    mounted() {
        this.dataTypeOptions = this.producFieldMetaList.map(x => {
            return {
                code: x.name,
                name: x.name,
            }
        }),
        this.fieldList = this.producFieldMetaList
        this.product = this.item
        // if (this.orgType == 'SUPPLIER') {
        //     if (this.$parent.$parent.status != undefined && this.$parent.$parent.status == 'OPEN') {
        //         this.isShow = false
        //     }
        // }
        // if (this.orgType == 'PROVIDER') {
        //     if (this.$parent.$parent.$parent.recallItem.modifiedDatetime != null) {
        //         this.isShow = false
        //     }
        // }
    },
    methods: {
        fetchProductData() {
            if (this.product && this.product.fields) {
                return {
                    ...this.product,
                    fields: this.product.fields.map(x => {
                        return {
                            ...x,
                            value: this.fetchValueByType(x.type, x.value)
                        }
                    })
                }
            }
            return null
        },
        dateRange()
        {
            //compare start and end date
            var Expirationenddateindex=-1;var Expirationstartdateindex=-1
            var Distributionenddateindex=-1;var Distributionstartdateindex=-1
            var Manufacturingenddateindex=-1;var Manufacturingstartdateindex=-1

            for (let i = 0; i <= this.product.fields.length; i++)
            {
                try{
                    if(this.product.fields[i].field_name=='Expiration End Date')
                    {
                        Expirationenddateindex=i
                    } }
                catch{
                    console.log()
                }
                try{
                    if(this.product.fields[i].field_name=='Expiration Start Date' )
                    {
                        Expirationstartdateindex=i
                    }   }
                catch{
                    console.log()
                }
                try{
                    if(this.product.fields[i].field_name=='Distribution End Date')
                    {
                        Distributionenddateindex=i
                    } }
                catch{
                    console.log()
                }
                try{
                    if(this.product.fields[i].field_name=='Distribution Start Date' )
                    {
                        Distributionstartdateindex=i
                    } }
                catch{
                    console.log()
                }
                try{
                    if(this.product.fields[i].field_name=='Manufacturing End Date')
                    {
                        Manufacturingenddateindex=i
                    } }
                catch{
                    console.log()
                }
                try{
                    if(this.product.fields[i].field_name=='Manufacturing Start Date' )
                    {
                        Manufacturingstartdateindex=i
                    }   }
                catch{
                    console.log()
                }

            }
            if(Expirationenddateindex!=-1 && Expirationstartdateindex!=-1 )
            {
                if (this.product.fields[Expirationstartdateindex].value.valueDate > this.product.fields[Expirationenddateindex].value.valueDate)
                    this._showToast('Expiration end date should be greater than Expiration start date', {variant: 'danger'})
            }
            if(Distributionenddateindex!=-1&& Distributionstartdateindex!=-1 && this.product.fields[Distributionstartdateindex].value.valueDate  > this.product.fields[Distributionenddateindex].value.valueDate)
            {
                this._showToast('Distribution end date should be greater than Distribution start date', {variant: 'danger'})
            }
            if(Manufacturingenddateindex!=-1&& Manufacturingstartdateindex!=-1 &&this.product.fields[Manufacturingstartdateindex].value.valueDate  > this.product.fields[Manufacturingenddateindex].value.valueDate)
            {
                this._showToast('Manufacturing end date should be greater than Manufacturing start date', {variant: 'danger'})
            }
        },
        fetchValueByType(dataType, valueObj){
            let _value = null
            switch (dataType) {
            case this.enumProductFieldDataType.TEXT:
                _value = { valueText: valueObj.valueText.trim() }
                break
            case this.enumProductFieldDataType.NUMBER:
                _value = { valueNumber: valueObj.valueNumber }
                break
            case this.enumProductFieldDataType.DATE:
                var date=  moment(valueObj.valueDate).format('MM/DD/YYYY')
                _value = { valueDate: date }
                break
            case this.enumProductFieldDataType.LIST_SINGLE:
                _value = { valueListSingle: valueObj.valueListSingle }
                break
            case this.enumProductFieldDataType.LIST_MULTIPLE:
                _value = { valueListMultiple: valueObj.valueListMultiple }
                break
            case this.enumProductFieldDataType.YES_NO:
                _value = { valueYesNo: valueObj.valueYesNo }
                break
            case this.enumProductFieldDataType.TRUE_FALSE:
                _value = { valueTrueFalse: valueObj.valueTrueFalse }
                break
            }
            return _value
        },

        addNewField() {
            this.product.fields.push({field_name: '', value: null})
        },
        fetchFieldType(fieldName) {
            const _field = this.fieldList.find(x => x.name === fieldName)
            return _field ? _field.type : ''
        },
        fetchFieldValueOptions(fieldName) {
            const _field = this.fieldList.find(x => x.name === fieldName && [this.enumProductFieldDataType.LIST_SINGLE, this.enumProductFieldDataType.LIST_MULTIPLE].includes(x.type))
            if (_field) {
                return _field.list_vals
            } else {
                return []
            }
        },
        fetchFieldDefaultValue(fieldName) {
            const _field = this.fieldList.find(x => x.name === fieldName && [this.enumProductFieldDataType.LIST_SINGLE, this.enumProductFieldDataType.LIST_MULTIPLE].includes(x.type))
            if (_field) {
                return _field.list_vals
            } else {
                return []
            }
        },
        removeField(index) {
            if (this.product.fields && this.product.fields.length > 0) {
                this.product.fields.splice(index, 1)
            }
        },
        removeProduct() {
            this.$emit('remove-product')
        },

        handleChangeDataType(fieldName, index) {
            if (fieldName) {
                const _field = this.fieldList.find(x => x.name === fieldName)
                let _defaultValue = this.fetchInitialDefaultValueByDataType(_field.type)
                _defaultValue = {
                    ..._defaultValue,
                    ..._field.default_val
                }

                if (_field.type === this.enumProductFieldDataType.DATE) {
                    if (_field.default_val && _field.default_val.valueDate === this.enumDateTypeDefaultValue.CURRENT_TIME) {
                        _defaultValue = {
                            ..._defaultValue,
                            valueDate: new Date()
                        }
                    } else {
                        _defaultValue = {
                            ..._defaultValue,
                            valueDate: null
                        }
                    }
                }
                this.product.fields[index].type = _field.type
                this.product.fields[index].value = _defaultValue

            } else {
                this.product.fields[index].type = null
                this.product.fields[index].value = null
            }
        },

        fetchInitialDefaultValueByDataType(dataType) {
            let _defaultValue = null
            switch (dataType) {
            case this.enumProductFieldDataType.TEXT:
                _defaultValue = { valueText: '' }
                break
            case this.enumProductFieldDataType.NUMBER:
                _defaultValue = { valueNumber: null }
                break
            case this.enumProductFieldDataType.DATE:
                _defaultValue = { valueDate: null }
                break
            case this.enumProductFieldDataType.LIST_SINGLE:
                _defaultValue = { valueListSingle: '' }
                break
            case this.enumProductFieldDataType.LIST_MULTIPLE:
                _defaultValue = { valueListMultiple: [] }
                break
            case this.enumProductFieldDataType.YES_NO:
                _defaultValue = { valueYesNo: null }
                break
            case this.enumProductFieldDataType.TRUE_FALSE:
                _defaultValue = { valueTrueFalse: null }
                break
            }
            return _defaultValue
        },
        ChangeIdentifires(fields, field, event) {
            let rownumber = field.options.filter(x => x.value == event)[0].rowNumber
            let uniquefields = fields.filter(x => x.is_unique_identifier == true && x.field_name != field.field_name)
            for (let index = 0; index < uniquefields.length; index++) {
                let uniquefield = fields.filter(x => x.is_unique_identifier == true && x.field_name == uniquefields[index].field_name)[0]
                uniquefield.selected_value = uniquefield.options.filter(x => x.rowNumber == rownumber)[0].value
            }
        }

    }
}