import VueDropzone from 'vue2-dropzone'
import product from '../product'
import supplierRecallService from '../../../../../services/supplier/supplier-recall'
import { Product } from '../../../../../misc/commons'
import _ from 'lodash'
import excelParser from '../../../../../services/excel-parser'
import moment, { unix } from 'moment-timezone'
import { ENUM_PRODUCT_FIELD_DATA_TYPE, ENUM_DATE_TYPE_DEFAULT_VALUE } from '../../../../../misc/commons'
export default {
    components: {
        VueDropzone,
        product
    },
    data() {
        return {
            loadingInitial: true,
            communicationId: null,
            producFieldMetaList: [],
            products: [],
            errorList: [],
            dropzoneOptions: {
                url: '#',
                acceptedFiles: '.xlsx,.xls,.csv',
                maxFiles: 1,
                maxFilesize: 20,
                previewsContainer: false,
                autoProcessQueue: false
            },
            isFileSelect: false,
            file: null,
            isCustomizedContent: false,
            CustomizedfilesUpload: [],
            IsShowCustomizedView: false,
            CustomisedContentId: [],
            CustomLocations: [],
            OriginalProducts: [],
            enumProductFieldDataType: ENUM_PRODUCT_FIELD_DATA_TYPE,
            enumDateTypeDefaultValue: ENUM_DATE_TYPE_DEFAULT_VALUE,
        }
    },
    watch: {
        'CustomisedContentId'(val){

            if(this.CustomisedContentId!=undefined && this.CustomisedContentId.length>0 &&this.CustomisedContentId!=null)
            {
                this.isFileSelect = true
            }
            else{
                this.isFileSelect = false
            }

        },
    },
    methods: {
        forceRerender(communicationId, producFieldMetaList,isCustomizedContent) {
            this.loadingInitial = true
            this.isCustomizedContent = isCustomizedContent
            this.resetData()
            this.communicationId = communicationId
            this.producFieldMetaList = producFieldMetaList
            this.$refs.modal.show()
            this.loadingInitial = false
        },

        resetData() {
            this.file = null
            this.isFileSelect = false
            this.communicationId = null
            this.producFieldMetaList = []
            this.products = []
            this.errorList = []
        },

        onClose() {
            this.$refs.modal.hide()
            this.$emit('onClose')
        },
        addFile(file) {
            this.isFileSelect = false
            this.products=[]
            this.OriginalProducts=[]
            if (file.name && _.isArray(file.name.split('.'))) {
                let ext = file.name.split('.')
                if(this.isCustomizedContent)
                {
                    if (['xlsx'].includes(ext[ext.length - 1])) {
                        this.file = file
                        if (this.isCustomizedContent) {
                            let formData = new FormData()
                            formData.append('file', this.file)
                            excelParser.getExcelHeaders(formData).then(res => {
                                var checkfileformat=res.data.d.includes('Location ID','#Description')
                                if(checkfileformat==true)
                                {
                                    if (!res.error) {
                                        this.CustomizedfilesUpload=[]
                                        this.CustomisedContentId=[]
                                        res.data.d.forEach(x => {
                                            if(x!='Location ID' && x!='#Description')
                                            {
                                                this.CustomizedfilesUpload.push({
                                                    text: x,
                                                    value: x
                                                })
                                            }
                                        })
                                    }
                                }
                                else{
                                    this._showToast('Please choose the correct format of the product template', { variant: 'danger' })
                                    this.file=null
                                }

                            })
                        }
                        else{
                            this.isFileSelect = true
                        }
                        this.IsShowCustomizedView = true
                    } else {
                        this._showToast('Only accept xlsx file format', { variant: 'danger' })
                    }
                }
                else{
                    if (['xls', 'xlsx', 'csv'].includes(ext[ext.length - 1])) {
                        this.file = file

                        let formData = new FormData()
                        formData.append('file', this.file)

                        this.isFileSelect = true
                    } else {
                        this._showToast('Only accept xls, xlsx, csv file format', { variant: 'danger' })
                    }
                }
            }
        },

        submitFile(e) {
            if (this.isFileSelect && this.file) {

                let formData = new FormData()
                formData.append('id', this.communicationId)
                formData.append('isCustomisdContent', this.isCustomizedContent)
                formData.append('UniqueColumn', this.CustomisedContentId)
                formData.append('file', this.file)
                this.products = []
                this.errorList = []
                supplierRecallService.readProductFileAtStep1Products(formData).then(res => {
                    if (!res.error) {
                        if(res.data.d.existinguniquecheck)
                        {
                            this.OriginalProducts = res.data.d.success_list_duplicate.map((x, index) => {
                                if(x.customisedLocationId.length>0)
                                {
                                    this.CustomLocations.push(x.customisedLocationId[0].LoactionId)
                                }
                                return new Product({
                                    id: null,
                                    description: x.description,
                                    fields: x.fields.map(y => {
                                        let _val = y.value
                                        if (y.type === 'Date') {
                                            _val = { valueDate: y.value.valueDate }
                                        }
                                        return {
                                            ...y,
                                            value: _val
                                        }
                                    }),
                                    order: index + 1
                                })
                            })
                            this.isFileSelect = false
                            this.file = null
                            if (res.data.d.checkduplicate) {
                                if(res.data.d.existinguniquecheck)
                                {
                                    this._showConfirm('There are duplicate records in the excel file. Please confirm if the file still needs to be uploaded by dropping the duplicate records?').then(confirm => {
                                        if (confirm) {
                                            this.products =res.data.d.success_list.map((x, index) => {
                                                return new Product({
                                                    id: null,
                                                    description: x.description,
                                                    fields: x.fields.filter(s=>s.field_name!='Location ID').map(y => {
                                                        if(this.CustomisedContentId.includes(y.field_name))
                                                        {
                                                            y.is_unique_identifier=true
                                                        }
                                                        let _val = y.value
                                                        if (y.type === 'Date') {
                                                            _val = { valueDate: y.value.valueDate }
                                                        }
                                                        return {
                                                            ...y,
                                                            value: _val
                                                        }
                                                    }),
                                                    order: index + 1
                                                })
                                            })
                                            this.errorList = res.data.d.error_list
                                            if(res.data.d.success_list.length>0)
                                            {
                                                this._showToast('Read file successfully')
                                            }
                                            else{
                                                this.CustomisedContentId=[]
                                                this.IsShowCustomizedView=false
                                                this.CustomizedfilesUpload=[]
                                                if(res.data.d.success_list.length<=0 && res.data.d.success_list.length<=0 && res.data.d.checkduplicate)
                                                {
                                                    this._showToast('All records are duplicate', { variant: 'danger' })
                                                }
                                                else{
                                                    this._showToast('Please choose the correct format of the product template', { variant: 'danger' })
                                                }

                                            }
                                        }
                                        else{
                                            this.file=null
                                            this.CustomisedContentId=[]
                                            this.CustomizedfilesUpload=[]
                                            this.IsShowCustomizedView=false
                                        }
                                    })
                                    e.stopPropagation()
                                }
                                else{
                                    this._showToast('Please choose same unique column(s) ', { variant: 'danger' })
                                    this.CustomisedContentId=[]
                                    this.CustomizedfilesUpload=[]
                                    this.IsShowCustomizedView=false
                                }
                            }
                            else{
                                this.products = res.data.d.success_list.map((x, index) => {
                                    return new Product({
                                        id: null,
                                        description: x.description,
                                        fields: x.fields.filter(s=>s.field_name!='Location ID').map(y => {
                                            let _val = y.value
                                            if(this.CustomisedContentId.includes(y.field_name))
                                            {
                                                y.is_unique_identifier=true
                                            }
                                            if (y.type === 'Date') {
                                                _val = { valueDate: y.value.valueDate }
                                            }
                                            return {
                                                ...y,
                                                value: _val
                                            }
                                        }),
                                        order: index + 1
                                    })
                                })
                                this.errorList = res.data.d.error_list
                                if(res.data.d.success_list.length>0)
                                {
                                    this._showToast('Read file successfully')
                                }
                                else{

                                    this.CustomisedContentId=[]
                                    this.IsShowCustomizedView=false
                                    this.CustomizedfilesUpload=[]
                                    if(res.data.d.error_list.length<=0)
                                    {
                                        this._showToast('The file contains no valid data', { variant: 'danger' })
                                    }
                                }
                            }
                        }
                        else{
                            this._showToast('Please choose same unique column(s) ', { variant: 'danger' })
                            this.CustomisedContentId=[]
                            this.CustomizedfilesUpload=[]
                            this.file=null
                            this.IsShowCustomizedView=false
                        }
                    }

                })

            }
        },

        save() {
            if (this.products && this.products.length > 0) {
                this.$refs['observer'].validate().then(isValid => {
                    if (isValid) {
                        let _products = this.fetchProductItemData()
                        let DuplicateProducts=this.fetchDuplicateProducts()
                        if (_products && _products.length > 0 && _products.length === this.products.length) {
                            supplierRecallService.addNewProductsAtStep1Products({
                                id: this.communicationId,
                                products: _products,
                                duplicateproducts: DuplicateProducts,
                                customisedContentId: this.CustomisedContentId,
                                customLocations: this.CustomLocations,
                                isCustomizedContent: this.isCustomizedContent
                            }).then(res => {
                                if (!res.error) {
                                    this.$refs.modal.hide()
                                    this.resetData()
                                    this.$emit('onCompleted')
                                }
                            })
                        }
                    } else {
                        if(this.CustomisedContentId.length==0)
                        {
                            this._showToast('It is required to choose at least one column', { variant: 'danger' })
                        }
                        else{
                            this._showToast('Please review product value', { variant: 'danger' })
                        }

                    }
                })
            }

        },

        fetchProductItemData() {
            let _products = []
            for (let i = 0; i < this.products.length; i++) {
                let _product = this.$refs.successProducts[i].fetchProductData()
                if (_product) {
                    _products.push(_product)
                }
            }
            return _products
        },
        fetchDuplicateProducts() {
            let _products = []
            for (let i = 0; i < this.OriginalProducts.length; i++) {
                let _product= {
                    ...this.OriginalProducts[i],
                    fields: this.OriginalProducts[i].fields.map(x => {
                        return {
                            ...x,
                            value: this.fetchValueByType(x.type, x.value)
                        }
                    })
                }
                if (_product) {
                    _products.push(_product)
                }
            }
            return _products
        },

        fetchValueByType(dataType, valueObj){
            let _value = null
            switch (dataType) {
            case this.enumProductFieldDataType.TEXT:
                _value = { valueText: valueObj.valueText.trim() }
                break
            case this.enumProductFieldDataType.NUMBER:
                _value = { valueNumber: valueObj.valueNumber }
                break
            case this.enumProductFieldDataType.DATE:
                _value = { valueDate: valueObj.valueDate }
                break
            case this.enumProductFieldDataType.LIST_SINGLE:
                _value = { valueListSingle: valueObj.valueListSingle }
                break
            case this.enumProductFieldDataType.LIST_MULTIPLE:
                _value = { valueListMultiple: valueObj.valueListMultiple }
                break
            case this.enumProductFieldDataType.YES_NO:
                _value = { valueYesNo: valueObj.valueYesNo }
                break
            case this.enumProductFieldDataType.TRUE_FALSE:
                _value = { valueTrueFalse: valueObj.valueTrueFalse }
                break
            }
            return _value
        },

        removeProduct(index) {
            if (this.products && this.products.length > 0) {
                this.products.splice(index, 1)
            }
        },

        downloadTemplate() {
            supplierRecallService.downloadProductTemplate(this.communicationId)
        }
    }
}