import {http, param} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const templateActionService = {
    findMetaSupplier() {
        return http.get(`${API_ROOT}/supplier/templateAction/meta-supplier`).then(resp => {
            return resp
        })
    },
    //Provider Internal Actions
    findInternalActions(eventType) {
        return http.get(`${API_ROOT}/supplier/templateAction/internal-actions-meta/${eventType}`).then(resp => {
            return resp
        })
    },
    saveInternalActions(eventType, payload) {
        return http.post(`${API_ROOT}/supplier/templateAction/save-actions-meta/${eventType}`,payload).then(resp => {
            return resp
        })
    },
    getRecallMetaData (eventType) {
        return http.get(`${API_ROOT}/supplier/templateAction/internal-description-meta/${eventType}`).then(resp => {
            return resp
        })
    },
}

export default templateActionService
